import React from "react";
import styles from "./Table.module.css";
import Arrow from "./Arrow";
import { useTranslation } from "react-i18next";

const Table = ({
	direction,
	childSelect,
	tableHeader,
	tableItems,
	tableFooter = null,
	isFirstSortable = true,
	boldColumn,
	...props
}) => {
	const { t } = useTranslation();
	return (
		<div
			style={{
				maxHeight: "100%",
				overflow: "auto",
				padding: "0 10px 0 20px",
				width: "100%",    
				// width: "100vw",
			}} 
			// className="table-responsive"          
			className={`${styles.tableContainer}`} 
		>
			<table
				className={styles.table}
				style={{ height: "fit-content", width: "100%", overflowY: "auto" }}
			>
				<thead>
					<tr>
						{tableHeader.map((ele, index) => (
							<th
								key={index}
								onClick={ele[1] ? ele[1] : null}
								style={ele[2]}
								data-title={ele[0]}
							>
								{typeof ele[0] === "string" ? t(ele[0]) : ele[0]}
								{ele[1] ? (
									<Arrow
										direction={direction[isFirstSortable ? index - 1 : index]}
									/>
								) : null}
							</th>
						))}
					</tr>
				</thead>
				<tbody className={styles.tableBody}>
					{" "}
					{props.data && props.data.map(tableItems)}{" "}
				</tbody>
				<tfoot style={{ backgroundColor: "#304391", color: "white" }}>
					<tr>
						{tableFooter &&
							tableFooter.map((ele, index) => {
								return (
									<td
										key={index}
										colspan={
											typeof ele === "string"
												? ele.includes("Total number")
													? 3
													: 1
												: 1
										}
									>
										<p
											style={{
												margin: "16px 8px",
												fontSize: "13px",
												fontWeight: "bolder",
												textOverflow: "ellipsis",
												overflow: "hidden",
											}}
										>
											{t(ele)}
										</p>
									</td>
								);
							})}
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

export default Table;
