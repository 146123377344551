import React, { useState } from "react";
import { MDBIcon } from "mdbreact";
const DropDownMenu = ({
  options,
  icon = "cog",
  right = "30px",
  width = "200px",
  img,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <button
        onClick={() => setIsOpen((state) => !state)}
        onBlur={() => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            setIsOpen(false);
          }
        }}
        style={{
          border: "none",
          backgroundColor: "white",
          borderRadius: "50%",
          width: "40px",
            height: "40px",
            padding: 0,
        }}>
        {img ? (
          <img src={img} style={{ borderRadius: "50%", width: "90%", height: "90%" }} />
        ) : (
          <MDBIcon icon={icon} className="indigo-text" size="lg" />
        )}
      </button>
      <div
        onClick={(event) => event.stopPropagation()}
        style={{ position: "absolute", right: right, zIndex: 1000 }}>
        <div
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            borderRadius: "4px",
            marginTop: "4px",
          }}>
          {isOpen ? (
            <div
              style={{
                backgroundColor: "white",
                margin: 0,
                padding: "4px 6px",
                zIndex: 1000,
                width: width,
                borderRadius: "4px",
              }}>
              {options.map((ele) => (
                <button
                  disabled={disabled}
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "white",
                    padding: "4px",
                    textAlign: "left",
                    borderRadius: "12px",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    console.log("click data is open");
                    ele.click();
                    setIsOpen(false);
                  }}>
                  {ele.name}
                </button>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DropDownMenu;
{
  /* <ul
style={{
  listStyleType: "none",
  margin: 0,
  padding: "4px 6px",
  zIndex: 1000,
  position: "absolute",
  width: width,
}}
>
{options.map((ele) => (
  <li style={{ width: "100%" }}>
    <button
      style={{
        width: "100%",
        border: "none",
        backgroundColor: "white",
        padding: "4px",
        textAlign: "left",
        borderRadius: "12px",
      }}
      onClick={(event) => {
        event.stopPropagation();
        console.log("click data is open");
        ele.click();
        setIsOpen(false);
      }}
    >
      {ele.name}
    </button>
  </li>
))}
</ul> */
}
