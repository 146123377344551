const config = {

/* 	
	devurl: 'https://localhost:3443',
	   authurl: 'https://localhost:3443',
	   socketurl: 'https://localhost:3443',
 */

	devurl: 'https://server.stepswatch.co.in',
	   authurl: 'https://server.stepswatch.co.in',
	   socketurl: 'https://server.stepswatch.co.in',
	  


	//devurl: "https://ec2-13-38-108-41.eu-west-3.compute.amazonaws.com:3443",
	//authurl: "https://ec2-13-38-108-41.eu-west-3.compute.amazonaws.com:3444",
	pyurl: "http://ec2-15-237-116-148.eu-west-3.compute.amazonaws.com",
	//socketurl: "https://ec2-13-38-108-41.eu-west-3.compute.amazonaws.com:3443",
	mobileurl: "http://ec2-35-180-192-89.eu-west-3.compute.amazonaws.com:4000",
	mobilesocketurl:
		"http://ec2-35-180-192-89.eu-west-3.compute.amazonaws.com:8080",
	stripe_key:
		"pk_test_51JQXq6SI31FIQ1naOuc7A2SPj9g4dntL84Kwwz88AaL86o9MOV8dJvZTIGAUaNV6W1OsQTGfBmtarB5b0FF3lUwG00cZgxA4EE",
	stripe_account: "acct_1JQXq6SI31FIQ1na",
	stripe_key_france:
		"pk_test_51JAvtWACRnBrWuGYeyG5oX6eOdHKq3CEYEZYm7jEJ2w2Ics6G9uspxqVJTRJ9xTIQ4XCvEwSahJD2RskPd0UnVTY00Yk2LYVak",
	stripe_account_france: "acct_1JAvtWACRnBrWuGY",
};
export default config;
