import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect } from "react-router-dom";
import "./css/main.css";
import logo from "../public/logo.png";
import {
	login,
	Reset,
	addConfig,
	addSubmodule,
	addKeywords,
	addId,
	addAccessibleModulesData,
} from "../Redux/actions/actionCreator/data.action";
import config from "../config";
import Loading from "../loadingcompo/loading";
import { Button, Form, FormGroup, Input, Col, Row } from "reactstrap";
import { allowedModulesHandler } from "../configHandler";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LanguageSelect from "./LanguageSelection";
import { useTranslation } from "react-i18next";
import styles from "./Logincomponent.module.scss";

function Login() {
	const [userid, setuserid] = useState("");
	const [password, setpassword] = useState("");
	const dispatch = useDispatch();
	const [loading, setloading] = useState(false);
	const [errr, setErr] = useState("");
	const [neterr, setneterr] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [redirect, setredirect] = useState(false);

	const { t } = useTranslation();

	const handlesubmit = (event) => {
		event.preventDefault();
		let resok = true;
		console.log("logging you in...");
		if (userid === "") {
			console.log("email empty line 22 login");
		} else {
			setloading(true);
			fetch(`${config.devurl}/manager/login`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "same-origin",
				body: JSON.stringify({
					username: userid.trimEnd(),
					password: password,
				}),
			})
				.then((res) => {
					resok = res.ok;
					console.log("login ", res);
					return res.json();
				})
				.then((data) => {
					console.log("login data", data);
					console.log("login data labels", data.labels);
					// console.log("config data", allowedModulesHandler(data.labels));
					setErr("");
					setneterr("");
					if (resok) {
						if (data.flag === false) {
							var token = data.token;
							dispatch(Reset(token));
							setredirect("reset");
							setuserid("");
							setpassword("");
							setloading(false);
							setErr("");
						} else {
							console.log("if else statement inside login line no-51", data);
							localStorage.setItem("token", JSON.stringify(data.token));
							localStorage.setItem("username", userid);
							localStorage.setItem(
								"professionalLabels",
								JSON.stringify({ modules: data.professionalLabels })
							);
							localStorage.setItem(
								"adminLabels",
								JSON.stringify({ modules: data.adminLabels })
							);
							localStorage.setItem("id", data.labels.manager);

							localStorage.setItem("accountType", data.type);
							localStorage.setItem("professionalPhoto", data.professionalPhoto);
							if (data.type === "daycare") {
								const payload = data.adminLabels;
								Object.keys(payload).forEach((key) => {
									Object.keys(payload[key].subModules).forEach(
										(ele) =>
											(payload[key].subModules[ele].accessibility = "Edit")
									);
								});
								// console.log(payload, "Payload")
								dispatch(addAccessibleModulesData(payload));
							} else {
								console.log("data.modules", data.labels.modules);
								localStorage.setItem(
									"payload",
									JSON.stringify({ modules: data.labels.modules })
								);
								dispatch(addAccessibleModulesData(data.labels.modules));
							}

							const [modulesData, subModuleData] = allowedModulesHandler(
								data.labels
							);
							localStorage.setItem(
								"config",
								JSON.stringify({
									data: [...modulesData],
									subModule: [...subModuleData],
									keywords: data.labels.keywordLabels,
									id: data.labels.manager,
								})
							);
							console.log("config data", allowedModulesHandler(data.labels));
							dispatch(login(data.token));
							dispatch(addConfig(modulesData));
							dispatch(addSubmodule(subModuleData));
							dispatch(addKeywords(data.labels.keywordLabels));

							setloading(false);
							setErr("");
							//console.log("if else statement inside login line no-51111", localStorage);
						}
					} else {
						if (data.error) {
							setErr(data.error);
						} else {
							setErr("userid or password incorrect");
						}

						setloading(false);
					}
				})
				.catch((error) => {
					setErr("");
					setloading(false);
					console.log("login data error", error);
					setneterr(
						"Either Server out of reach or server internal error",
						error
					);
				});
			/* localStorage.setItem('token', (JSON.stringify('token')));
							dispatch(login('token'))
							setloading(false)*/
		}
	};

	const renderel = () => {
		return (
			<p className="alert11" style={{ color: "red" }}>
				{errr}
			</p>
		);
	};

	function handleforgetpass() {
		console.log("working");
		//var token='bc'
		//dispatch(Reset(token))
		setredirect("forget");
	}
	if (redirect == "reset") {
		return <Redirect to="/resetpassword" />;
	} else if (redirect == "forget") {
		console.log("working1");
		return <Redirect to="/forgetpass/user_id" />;
	} else {
		return (
			<div style={{ height: "100vh", overflow: "hidden" }}>
				<LanguageSelect />
				<div className="w-100 p-3" style={{ margin: "auto", height: "100vh" }}>
					{loading ? (
						<Loading />
					) : (
						<div className="row" style={{ height: "100vh" }}>
							<div className={`col-sm-2 col-md-3 col-lg-3 ${styles.design}`}>
								<div
									className="orange-circle animatedCircle"
									style={{
										position: "absolute",
										left: "3%",
										top: "11%",
										width: "15vh",
										height: "15vh",
										borderRadius: "50%",
										backgroundImage:
											"linear-gradient(180deg, #fb4d3d 100%, #fff)",
										willChange: "transform",
										transition: "transform 450ms",
									}}
								></div>
								<div
									className="green-circle animatedCircle"
									style={{
										position: "absolute",
										left: "3%",
										top: "60%",
										width: "35vh",
										height: "35vh",
										borderRadius: "50%",
										backgroundImage:
											"linear-gradient(180deg, #0ead69 100%, #fff)",
										willChange: "transform",
										transition: "transform 450ms",
									}}
								></div>
								<div
									className="yellow-sqaure animatedCircle"
									style={{
										position: "absolute",
										left: "40%",
										top: "23vh",
										width: "28px",
										height: "28px",
										borderStyle: "solid",
										borderWidth: "3px",
										borderColor: "#ffd23f",
										backgroundColor: "transparent",
										willChange: "transform",
										transition: "transform 450ms",
									}}
								></div>
							</div>
							<div className="col-sm-8 col-md-6  col-lg-6 log-margin">
								<img
									src={logo}
									alt="Logo"
									width="225px"
									height="65px"
									style={{
										display: "block",
										margin: "auto",
										marginBottom: "50px",
										marginTop: "50px",
									}}
								/>
								<div
									className="border log-margin py-5 px-4 shadow"
									style={{
										maxHeight: "500px",
										maxWidth: "450px",
										position: "absolute",
										zIndex: "2",
										left: "0",
										right: "0",
										marginLeft: "auto",
										marginRight: "auto",
										backgroundColor: "white",
									}}
								>
									{errr ? renderel() : null}
									{neterr ? (
										<label style={{ color: "red" }}>{neterr}</label>
									) : null}
									<p
										className="text-center font-weight-bold pb-4 "
										color="black"
										style={{ fontSize: 22 }}
									>
										{t("login")}
									</p>
									<Form onSubmit={handlesubmit} style={{ height: "100%" }}>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
										>
											<FormGroup
												row
												className="mt-3 w-100 align-items-center justify-content-center"
											>
												<Col md={12}>
													<Input
														className="inputfield"
														type="text"
														id="userid"
														name="Userid"
														style={{ border: "1px solid #ccc" }}
														placeholder="User ID"
														onChange={(e) => setuserid(e.target.value)}
														value={userid}
														required
													/>
												</Col>
												{/* <Visibility style={{ color: "white" }} /> */}
											</FormGroup>
											<FormGroup
												row
												className="mt-3 w-100 align-items-center justify-content-center"
											>
												<Col md={12} className="flexContainer w-100">
													<div
														className="d-flex align-items-center justify-content-center"
														style={{ position: "relative" }}
													>
														<Input
															type={showPassword ? "text" : "password"}
															id="password"
															name="password"
															placeholder={t("password")}
															value={password}
															onChange={(e) => setpassword(e.target.value)}
															required
														/>
														{showPassword ? (
															<Visibility
																style={{
																	position: "absolute",
																	right: "15px",
																	top: "5px",
																}}
																onClick={() => {
																	setShowPassword(!showPassword);
																}}
															/>
														) : (
															<VisibilityOff
																style={{
																	position: "absolute",
																	right: "15px",
																	top: "5px",
																}}
																onClick={() => {
																	setShowPassword(!showPassword);
																}}
															/>
														)}
													</div>
												</Col>
											</FormGroup>
											<FormGroup
												row
												className="align-items-center mt-5 w-100 mx-auto"
											>
												<Col
													md={{ size: 4 }}
													sm={{ size: 10 }}
													style={{ paddingRight: "0px", paddingLeft: "0px" }}
												>
													<Button
														type="Submit"
														style={{
															background: "#3f51b5",
															color: "white",
															fontSize: "1.25rem",
															padding: "6px 12px",
														}}
														text-shadow="red"
														size="md"
														block
														color="#3f51b5"
													>
														{t("login")}
													</Button>
												</Col>
												<Col
													md={{ size: 8 }}
													sm={{ size: 10 }}
													style={{
														alignContent: "center",
														alignItems: "center",
														justifyContent: "center",
														justifyItems: "center",
														textAlign: "right",
														padding: "0px",
													}}
												>
													<label
														style={{
															color: "#304391",
															border: "none",
															alignContent: "center",
															alignItems: "center",
															justifyContent: "center",
															justifyItems: "center",
															marginLeft: "0%",
															marginTop: "0px",
															marginBottom: "0px",
															cursor: "pointer",
															padding: "0px",
														}}
														onClick={() => handleforgetpass()}
													>
														{t("forgotten_password")}
													</label>
												</Col>
											</FormGroup>
										</div>
									</Form>
								</div>
							</div>
							<div
								className={`col-sm-2 col-md-3 col-lg-3 log-margin ${styles.design}`}
							>
								<div
									className="yellow-circle animatedCircle"
									style={{
										position: "absolute",
										left: "auto",
										top: "14%",
										bottom: "44%",
										right: "3%",
										width: "15vh",
										height: "15vh",
										borderRadius: "50%",
										backgroundImage:
											"linear-gradient(180deg, #ffd23f 100%, #fff)",
										willChange: "transform",
										transition: "transform 450ms",
									}}
								></div>
								<div
									className="pink-circle animatedCircle"
									style={{
										position: "absolute",
										left: "auto",
										top: "50%",
										right: "3%",
										width: "35vh",
										height: "35vh",
										marginTop: "104px",
										borderRadius: "50%",
										display: "flex",
										flexDirection: "column",
										backgroundImage:
											"linear-gradient(180deg, #ff3e5c 100%, #fff)",
										willChange: "transform",
										transition: "transform 450ms",
									}}
								></div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default Login;
