import React from "react";
const ChildDashboard = React.lazy(() =>
  import(/* webpackChunkName: "childDashboard" */ "./childdetail/ChildDashboard/ChildDashboard")
);
const ChildProfile = React.lazy(() =>
  import(/* webpackChunkName: "childProfile" */ "./childdetail/ChildProfile/ChildProfile")
);
const Enrollment = React.lazy(() =>
  import(/* webpackChunkName: "enrollment" */ "./childdetail/Enrollment/Enrollment")
);
const ChildRegister = React.lazy(() =>
  import(/* webpackChunkName: "childRegister2" */ "./childdetail/ChildRegister/ChildRegister")
);
const Billing = React.lazy(() =>
  import(/* webpackChunkName: "billing" */ "./childdetail/Billing/Billing")
);
const DaycareDetails = React.lazy(() =>
  import(/* webpackChunkName: "DaycareDetails" */ "./Daycare/DaycareDetails")
);
const ViewSections = React.lazy(() =>
  import(/* webpackChunkName: "ViewSections" */ "./Daycare/ViewSections")
);
const ViewGroups = React.lazy(() =>
  import(/* webpackChunkName: "ViewGroups" */ "./Daycare/ViewGroups")
);
const Presence = React.lazy(() =>
  import(/* webpackChunkName: "Presence" */ "./childdetail/Presence/Presence")
);
const Prospects = React.lazy(() =>
  import(/* webpackChunkName: "Prospects" */ "./childdetail/Prospects/Propects")
);

//const Reports = React.lazy(() =>
//  import(/* webpackChunkName: "Reports" */ "./Daycare/FinancialReporting/Reports")
//); 
const PnL = React.lazy(() =>
  import(/* webpackChunkName: "PnL" */ "./reports/PnL/Finance")
);

//const LiveDashboard = React.lazy(() =>
//  import(/* webpackChunkName: "LiveDashboard" */ "./Live/LiveDashboard")
//);

// RM: removal of staffmanagement folder from src
//const staffManagement = React.lazy(() =>
//  import(/* webpackChunkName: "staffManagement" */ "../staffmanagement/staffprofileDM")
//);
const PayRoll = React.lazy(() =>
  import(/* webpackChunkName: "payroll" */ "./StaffManagement/PayRoll")
);
const MyDay = React.lazy(() => import(/* webpackChunkName: "MyDay" */ "./Daycare/MyDay/MyDay"));
const profile = React.lazy(() => import(/* webpackChunkName: "profile" */ "./profile"));
const leaveCalendar = React.lazy(() =>
  import(/* webpackChunkName: "leaveCalendar" */ "./StaffManagement/LeaveCalender")
);
const staffProfile = React.lazy(() =>
  import(/* webpackChunkName: "leaveCalendar" */ "./StaffManagement/Profile/Profile")
);
const ViewSessions = React.lazy(() =>
  import(/* webpackChunkName: "SessionManagement" */ "./Daycare/ViewSessions")
);
const ViewTerms = React.lazy(() =>
  import(/* webpackChunkName: "TermsManagement" */ "./Daycare/ViewTerms")
);
const UserManagement = React.lazy(() =>
  import(/* webpackChunkName: "UserManagement" */ "./Daycare/UserManagement/UserManagement")
);

const CreateActivity = React.lazy(() =>
  import(/* webpackChunkName: "CreateActivity" */ "./Activities/CreateActivity/CreateActivity")
);
const ManageActivity = React.lazy(() =>
  import(/* webpackChunkName: "ManageActivity" */ "./Activities/ManageActivity/ManageActivity")
);

const Milestones = React.lazy(() =>
  import(/* webpackChunkName: "SessionManagement" */ "./Activities/Milestones/Milestones")
);
const Skill = React.lazy(() =>
  import(/* webpackChunkName: "SessionManagement" */ "./Activities/Skill/Skills")
);
const Category = React.lazy(() =>
  import(/* webpackChunkName: "SessionManagement" */ "./Activities/Category/Category")
);



//const Finance = React.lazy(() => import("./Daycare/Finance"));
export const subModuleData = {
  childManagement: [
    {
      name: "childDashboard",
      url: "/childManagement/childDashboard",
      component: "ChildDashboard",
    },
    {
      name: "childProfile",
      url: "/childManagement/childProfile",
      component: "ChildProfile",
    },
    {
      name: "enrollment",
      url: "/childManagement/enrollment",
      component: "Enrollment",
    },
    {
      name: "registerChild",
      url: "/childManagement/childRegister",
      component: "ChildRegister",
    },
    {
      name: "billing",
      url: "/childManagement/billing",
      component: "Billing",
    },
    {
      name: "presence",
      url: "/childManagement/presence",
      component: "Presence",
    },
    {
      name: "prospects",
      url: "/childManagement/prospects",
      component: "Prospects",
    },
  ],
  daycare: [
    {
      name: "daycareDetails",
      url: "/myDaycareNew/details",
      component: "DaycareDetails",
    },
    {
      name: "sections",
      url: "/myDaycareNew/sections",
      component: "ViewSections",
    },
    {
      name: "groups",
      url: "/myDaycareNew/groups",
      component: "ViewGroups",
    },
  //  {
  //    name: "reports",
  //    url: "/myDaycareNew/reports",
  //    component: "Reports",
  //  },
    {
      name: "myDay",
      url: "/myDaycareNew/myday",
      component: "MyDay",
    },
    {
      name: "sessions",
      url: "/myDaycareNew/viewSessions",
      component: "ViewSessions",
    },
    {
      name: "terms",
      url: "/myDaycareNew/viewTerms",
      component: "ViewTerms",
    },
  //  {
  //    name: "finance",
  //    url: "/myDaycareNew/finance",
  //    component: "Finance",
   // },
    {
      name: "userManagement",
      url: "/myDaycareNew/userManagement",
      component: "UserManagement",
    },
  ],
  staffManagement: [
    { name: "profiles", url: "/staffmanagement/profile", component: "staffProfile" },
    { name: "payroll", url: "/staffmanagement/payroll", component: "payRoll" },
    { name: "leaveCalendar", url: "/staffmanagement/leavecalendar", component: "leaveCalendar" },
  ],
  live: [
    {
      name: "liveStream",
      url: "/live",
      component: "LiveDashboard",
    },
  ],
  reports: [
    {
      name: "pnl",
      url: "/pnl",
      component: "PnL",
    },
  ],
  activities: [
    {
      name: "createActivities",
      url: "/activity/create",
      component: "CreateActivity",
    },
    {
      name: "manageActivities",
      url: "/activity/manage",
      component: "ManageActivity",
    },
    {
      name: "milestones",
      url: "/activity/milestones",
      component: "Milestones",
    },
    {
      name: "category",
      url: "/activity/category",
      component: "Category",
    },
    {
      name: "skills",
      url: "/activity/skills",
      component: "Skills",
    },
   
  ],
};

export const components = {
  ChildDashboard: ChildDashboard,
  ChildProfile: ChildProfile,
  Enrollment: Enrollment,
  ChildRegister: ChildRegister,
  Billing: Billing,
  Presence: Presence,
  Prospects: Prospects,
  DaycareDetails: DaycareDetails,
  ViewSections: ViewSections,
  ViewGroups: ViewGroups,
  //Reports: Reports,
  MyDay: MyDay,
  ViewSessions: ViewSessions,
  ViewTerms: ViewTerms,
  //Finance: Finance,
  // RM removal of staffnamanegement from src folder
  //staffManagement: staffManagement,
  //LiveDashboard: LiveDashboard,
  PnL: PnL,
  payRoll: PayRoll,
  leaveCalendar: leaveCalendar,
  staffProfile: staffProfile,
  UserManagement: UserManagement,

  CreateActivity: CreateActivity,
  ManageActivity: ManageActivity,
  Milestones: Milestones,
  Category: Category,
  Skills: Skill,
};
